import React, { useState, useEffect, useContext } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { format, render, cancel, register } from "timeago.js";

import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";
import { api } from "../../../utils/Api";
import OrderChat from "../OrderChat/OrderChat";

export default function MyOrders(props: any) {
  const [orders, setOrders] = useState([]);
  const [ordersData, setOrdersData]: any = useState({});
  const [visible_content, SetVisibleContent] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const Spinner = () => <div className="loader-spinner mx-auto py-7"></div>;
  const context: any = useContext(DataContext);
  const [chatModal, SetChatModal]: any = useState({
    is_open: false,
    id: "",
    data: {},
  });
  const [ordered_product_spec, SetOrderedProductSpec] = useState({});
  const [width, SetWidth] = React.useState(innerWidth);
  const [outlet_context_data, SetOutletContextData]: any = useOutletContext();

  let order_details_call_interval: any;

  useEffect(() => {
    get_orders({
      page_no: page_no,
      load_more: false,
    });

    // order_details_call_interval = setInterval(function () {
    //   let order_ids: any = [];
    //   setOrdersData((prevalue: any) => {
    //     prevalue.map((ele: any) => {
    //       order_ids.push(ele.id);
    //     });
    //     let send_data: any = {
    //       order_ids: order_ids,
    //     };
    //     get_order_loop(send_data);

    //     return prevalue;
    //   });
    // }, 20000);

    if (width < 720) {
      SetOutletContextData((prevValue) => {
        prevValue.have_back = props.have_back;
        prevValue.have_back_value = false;
        prevValue.back_value = "";
        prevValue.title = "My Orders";
        prevValue.have_footer = true;
        prevValue.link = {
          rel: "canonical",
          href: "http://mysite.com/example",
        };
        return { ...prevValue };
      });
    } else {
      SetOutletContextData((prevValue) => {
        prevValue.have_back = props.have_back;
        prevValue.have_back_value = false;
        prevValue.back_value = "";
        prevValue.title = "My Orders";
        prevValue.have_footer = false;
        prevValue.link = {
          rel: "canonical",
          href: "http://mysite.com/example",
        };
        return { ...prevValue };
      });
      // let send_value = {
      //   have_back: props.have_back,
      //   title: "My Orders",
      //   have_footer: false,
      //   link: {
      //     rel: "canonical",
      //     href: "http://mysite.com/example",
      //   },
      // };
      // props.on_enter(send_value);
    }

    return () => {
      clearInterval(order_details_call_interval);
    };
  }, []);

  useEffect(() => {
    console.log("context.app_data.auth.logged_in on change :", context);
    if (props.need_login) {
      if (context.init_called && !context.app_data.auth.logged_in) {
        // window.location =
        //   "https://" +
        //   window.location.hostname +
        //   "/app_dev/login?r=" +
        //   window.location.href;
        window.location =
          "https://" +
          window.location.hostname +
          "/app/login?r=" +
          window.location.href;
      }
    }
  }, [context.app_data.auth.logged_in]);

  async function get_orders(get_value: any) {
    let pass_data = {
      get: {
        page: get_value.page_no,
        view: get_value.view_type,
      },
    };
    let response: any = await api("/orders", pass_data);
    console.log("get orders response :", response.response);
    if (response.status_code == 200) {
      SetVisibleContent(true);

      if (response.response.hasOwnProperty("orders")) {
        if (get_value.load_more) {
          setOrders((prevValue: any) => {
            response?.response?.orders?.map((ele: any) => {
              prevValue.push(ele);
            });
            return [...prevValue];
          });
        } else {
          setOrders(response.response.orders);
        }
        setOrdersData((prevalue: any) => {
          let prevalueObj: any = prevalue ? prevalue : {};
          response?.response?.orders?.map((ele: any) => {
            if (!prevalueObj.hasOwnProperty(ele.id)) {
              prevalueObj[ele.id] = {
                id: ele.id,
              };
            }
          });
          return { ...prevalueObj };
        });
      }

      if (response.response.hasOwnProperty("ordered_product_spec")) {
        SetOrderedProductSpec((prevalue) => {
          return { ...prevalue, ...response.response.ordered_product_spec };
        });
      }

      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response.response.next_page);
        SetPageNo((prevalue) => {
          prevalue = prevalue + 1;
          return prevalue;
        });
      }
    }
  }

  async function get_order_loop(get_data: any) {
    let pass_data = {
      post: {
        // page: get_value.page_no,
        // view: get_value.view_type,
        orders: encodeURI(JSON.stringify(get_data.order_ids)),
      },
    };
    console.log("get_order_loop pass_data :", pass_data);
    let response: any = await api("/order_list", pass_data);
    console.log("get order_list response :", response.response);
    if (response.status_code == 200) {
      // SetVisibleContent(true);
      if (response.response.hasOwnProperty("orders")) {
        setOrdersData(response.response.orders);
      }
    }
  }

  const OrderSkeleton = () => {
    return (
      <div className="card radius-10 shadow mb-2">
        <div className="row  p-2">
          <div className="col-6 d-flex align-items-center">
            <div className="del-type ">
              <Skeleton height={15} width={80} />
            </div>
          </div>
          <div className="col-6 text-end">
            <div className="o-date">
              <Skeleton height={15} width={50} />
            </div>
          </div>
        </div>
        <div className="shop-div row align-items-center">
          <div className="col-2 p-0">
            <Skeleton height={50} />
          </div>
          <div className="col-7 d-flex p-2">
            <div className="line-h-17px">
              <Skeleton height={15} width={150} />
              <span className="os-sta">
                <Skeleton height={15} width={80} />
              </span>
            </div>
          </div>
        </div>
        <div className="px-2 pt-3">
          <div className="d-flex mb-2">
            <Skeleton height={15} width={25} />
            <div className="os-qty">
              <Skeleton height={15} width={25} />
            </div>
            <div className="os-pname">
              <Skeleton height={15} width={250} />
            </div>
          </div>
          <div className="d-flex mb-2">
            <Skeleton height={15} width={25} />
            <div className="os-qty">
              <Skeleton height={15} width={25} />
            </div>
            <div className="os-pname">
              <Skeleton height={15} width={200} />
            </div>
          </div>
          <hr className="os-hr m-0" />
          <div className="row py-2">
            <div className="col-6 os-tot">
              <Skeleton height={15} width={75} />
            </div>
            <div className="col-6 end os-tot">
              <Skeleton height={15} width={50} />
            </div>
          </div>
        </div>
        <div className="shop-div row">
          <div className="col-2 p-0">
            <Skeleton height={50} />
          </div>
          <div className="col-7 d-flex p-2">
            <div className="line-h-17px">
              <span className="os-name">
                <Skeleton height={15} width={150} />
              </span>
              <span className="os-sta">
                <Skeleton height={15} width={50} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  async function call_seller(order_id: any) {
    setOrdersData((prevalue: any) => {
      prevalue[order_id].seller_call_loading = true;
      return { ...prevalue };
    });
    let pass_data = {
      get: {
        order_id: order_id,
        callto: "seller",
      },
    };
    let data_res = await api("/call", pass_data);
    if ((data_res.status_code = 200)) {
      setOrdersData((prevalue: any) => {
        prevalue[order_id].seller_call_loading = false;
        return { ...prevalue };
      });
      if (data_res?.response?.phone_number) {
        if (context?.application?.platform == "android") {
          window.open("tel:" + data_res?.response?.phone_number);
        } else {
          window.location.href = "tel:" + data_res?.response?.phone_number;
        }
      }
    }
  }

  async function chat(get_data: any) {
    setOrdersData((prevalue: any) => {
      prevalue[get_data.order_id].seller_chat_loading = true;
      return { ...prevalue };
    });
    let pass_data = {
      get: {
        order_id: get_data.order_id,
        chatto: get_data.chatto,
      },
    };
    let data: any = await api("/chat", pass_data);
    if (data.status_code == 200) {
      setOrdersData((prevalue: any) => {
        prevalue[get_data.order_id].seller_chat_loading = false;
        return { ...prevalue };
      });
      if (data?.response?.sb_user_id && data?.response?.channel_url) {
        SetChatModal({
          is_open: true,
          data: {
            orderId: get_data.order_id,
            sb_user_id: data?.response?.sb_user_id,
            channel_url: data?.response?.channel_url,
            title: data?.response?.user_name,
            profile_url: data?.response?.profile_url,
          },
        });
      }
    }
  }

  const OrderHeader = (oh_props: any) => {
    let prop_data: any = oh_props?.data;
    return (
      <div className="">
        <div className="d-flex p-2">
          <div className="d-flex align-items-center">
            <div className="del-type ">{prop_data?.delivery_type}</div>
            <div className="ms-2 pay_type">
              {prop_data?.payment_waiting
                ? "Waiting for payment status"
                : prop_data?.cod_order
                ? "COD"
                : "Online"}
            </div>
          </div>
          <div className="ms-auto text-end">
            <div className="o-date">
              {format(prop_data?.ordered_timeago, "en_US")}
            </div>
          </div>
        </div>
        {/* {prop_data?.order_status_id == 4 && !prop_data?.customer_cancelled ? (
          <div className="px-2 pb-2">
            <div className="os-status-red-sm fs-10px">
              {prop_data?.order_status}
            </div>
          </div>
        ) : null} */}
      </div>
    );
  };

  const OrderSeller = (oh_props: any) => {
    function get_nav_link(get_data: any) {
      let nav_link: any = "";
      // if (
      //   get_data?.order_status_id != 6 &&
      //   get_data?.order_status_id != 4 &&
      //   get_data?.order_status_id != 3
      // ) {
      //   nav_link = "/live_track?o_id=" + get_data?.id;
      // } else {
      //   nav_link = "/order_details?o_id=" + get_data?.id;
      // }
      nav_link = "/order_details?o_id=" + get_data.id;
      return nav_link;
    }

    let prop_data: any = oh_props?.data;
    return (
      <div className="shop-div d-flex align-items-center">
        <Link
          className="d-flex align-items-center"
          to={get_nav_link(prop_data)}
        >
          {prop_data?.brand_logo && (
            <div className="max-w-55p p-0 pe-1">
              <img
                src={AppConfig.CDN_Image_url + prop_data?.brand_logo}
                className="img-fluid order-img "
              />
            </div>
          )}
          <div className="d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">{prop_data?.brand_name} </span>
              <span className="os-sta ps-0">
                {prop_data?.order_status_id == 3 ? (
                  <span className="text-danger">is rejected your order</span>
                ) : prop_data?.order_status_id == 1 ? (
                  <span className="">is yet to accept your order</span>
                ) : (
                  prop_data?.delivery?.length > 0 &&
                  prop_data?.delivery[0].status_pretty != "" &&
                  prop_data?.delivery[0].status_pretty
                )}
              </span>
            </div>
          </div>
        </Link>
        {prop_data?.order_status_id == 4 && prop_data?.customer_cancelled ? (
          <div className="ms-auto p-0 end">
            {/* <div className="os-status-red pe-1">{prop_data?.order_status}</div> */}
            <div className="os-status-red pe-1">Canceled</div>
            <img
              src={
                AppConfig.CDN_Media_url + "d33ce1f2523b54f7672708ecfe300d76.svg"
              }
              className="w-16px"
            />
          </div>
        ) : prop_data?.order_failed == true ? (
          <div className="ms-auto p-0 end">
            <div className="os-status-red pe-1">Order Failed</div>
            <img
              src={
                AppConfig.CDN_Media_url + "d33ce1f2523b54f7672708ecfe300d76.svg"
              }
              className="w-16px"
            />
          </div>
        ) : prop_data?.order_status_id == 6 ? (
          <div className="ms-auto p-0 end">
            <div className="os-status pe-1">Delivered</div>
            <img
              src={
                AppConfig.CDN_Media_url + "58b18713d1577491802b06ac3c3bfd41.svg"
              }
              className="w-16px"
            />
          </div>
        ) : (
          (prop_data?.order_status_id == 2 ||
            prop_data?.order_status_id == 1 ||
            prop_data?.order_status_id == 5) && (
            <div className="ms-auto p-0 end">
              <div className="os-icon shadow center">
                {ordersData[prop_data?.id]?.seller_call_loading ? (
                  <Spinner />
                ) : (
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "a78b858bd49bf56c9fa495b641269677.svg"
                    }
                    onClick={() => call_seller(prop_data?.id)}
                    className="w-20px"
                  />
                )}
              </div>
              <div className="ms-3 os-icon shadow center">
                {ordersData[prop_data?.id]?.seller_chat_loading ? (
                  <Spinner />
                ) : (
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                    }
                    onClick={() => {
                      chat({ order_id: prop_data?.id, chatto: "seller" });
                    }}
                    className="w-20px"
                  />
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  const OrderPickupDropCompany = (oh_props: any) => {
    let prop_data: any = oh_props?.data;
    function get_nav_link(get_data: any) {
      let nav_link: any = "";
      // if (
      //   get_data?.order_status_id != 6 &&
      //   get_data?.order_status_id != 4 &&
      //   get_data?.order_status_id != 3
      // ) {
      //   nav_link = "/live_track?o_id=" + get_data?.id;
      // } else {
      //   nav_link = "/order_details?o_id=" + get_data?.id;
      // }
      nav_link = "/order_details?o_id=" + get_data.id;
      return nav_link;
    }

    return (
      <Link to={get_nav_link(prop_data)}>
        <div className="shop-div d-flex align-items-center">
          <div className="max-w-55p p-0 pe-1">
            <img
              src={
                AppConfig.CDN_Image_url +
                "bea45af075ec9c06eaa677896ee459de.jpeg"
              }
              className="img-fluid order-img "
            />
          </div>
          <div className="d-flex p-0">
            <div className="line-h-17px">
              <span className="os-name">SuperApp Delivery</span>
              <span className="os-sta">
                {prop_data?.delivery?.length > 0 &&
                  prop_data?.delivery[0].status_pretty != "" &&
                  prop_data?.delivery[0].status_pretty}
              </span>
            </div>
          </div>
          {prop_data?.order_status_id == 4 && prop_data?.customer_cancelled ? (
            <div className="ms-auto p-0 end">
              {/* <div className="os-status-red pe-1">
                {prop_data?.order_status}
              </div> */}
              <div className="os-status-red pe-1">Canceled</div>

              <img
                src={
                  AppConfig.CDN_Media_url +
                  "d33ce1f2523b54f7672708ecfe300d76.svg"
                }
                className="w-16px"
              />
            </div>
          ) : prop_data?.order_failed == true ? (
            <div className="ms-auto p-0 end">
              <div className="os-status-red pe-1">Order Failed</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "d33ce1f2523b54f7672708ecfe300d76.svg"
                }
                className="w-16px"
              />
            </div>
          ) : prop_data?.order_status_id == 6 ? (
            <div className="ms-auto p-0 end">
              <div className="os-status pe-1">Delivered</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "58b18713d1577491802b06ac3c3bfd41.svg"
                }
                className="w-16px"
              />
            </div>
          ) : null}
        </div>
      </Link>
    );
  };

  const OrderProductDetails = (opd_props: any) => {
    let prop_data: any = opd_props?.data;

    function generate_address(get_data: any) {
      let return_data: any = "";
      if (get_data.door_no != "") {
        return_data = return_data + get_data.door_no + ", ";
      }
      if (get_data.address != "") {
        return_data = return_data + get_data.address + ", ";
      }
      if (get_data.city != "") {
        return_data = return_data + get_data.city + ", ";
      }
      if (get_data.state != "") {
        return_data = return_data + get_data.state + ", ";
      }
      if (get_data.country != "") {
        return_data = return_data + get_data.country + " - ";
      }
      if (get_data.pincode != "") {
        return_data = return_data + get_data.pincode + ".";
      }

      return return_data;
    }

    function get_deit_icon(product_data: any) {
      let icon_value;
      SetOrderedProductSpec((prevalue: any) => {
        let key_value = "p_" + product_data.product_id;
        if (prevalue.hasOwnProperty(key_value)) {
          icon_value = prevalue[key_value][0]?.id;
        } else {
          icon_value = null;
        }
        return { ...prevalue };
      });
      return (
        // <img
        //   src={AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"}
        //   className="w-12px mt-1"
        // />
        <>
          {icon_value == AppConfig.vegetarian ? (
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="w-12px mt-1"
            />
          ) : null}
          {icon_value == AppConfig.non_vegetarian ? (
            <img
              src={
                AppConfig.CDN_Image_url + "5c7a11ae863a9b5cfa0e1844c7a186b9.png"
              }
              className="w-12px mt-1"
            />
          ) : null}
          {icon_value == AppConfig.eggetarian ? (
            <img
              src={
                AppConfig.CDN_Image_url + "fea28c272b8bbfb74f54b073bea5cfaa.png"
              }
              className="w-12px mt-1"
            />
          ) : null}
          {icon_value == AppConfig.vegan ? (
            <img
              src={
                AppConfig.CDN_Image_url + "1e2d32e8b1e3f2fd5eb65c3b8957674e.png"
              }
              className="w-12px mt-1"
            />
          ) : null}
        </>
      );
    }

    return (
      <div className="px-2 pt-3">
        {prop_data.sector_id == 4 ? (
          <>
            {/* pickup */}
            <div className="row pb-2">
              <div className="col-1 p-0">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "69e22d8519c6739a9feceef2d109c256.png"
                  }
                  className="w-20px"
                />
              </div>
              <div className="col-8 p-0">
                <div className="d-flex align-items-center pb-1">
                  <div className="os-dark pe-1">Pickup</div>
                  {prop_data?.pickup_address?.type_value != "" &&
                  prop_data?.pickup_address?.type_value != null ? (
                    <>
                      <div className="os-light pe-1">from</div>
                      <div className="os-dark uppercase">
                        {prop_data?.pickup_address?.type_value}
                      </div>
                    </>
                  ) : null}
                  {prop_data?.pickup_address?.other_type != "" &&
                  prop_data?.pickup_address?.other_type != null ? (
                    <>
                      <div className="os-light pe-1">from</div>
                      <div className="os-dark uppercase">
                        {prop_data?.pickup_address?.other_type}
                      </div>
                    </>
                  ) : null}
                </div>
                {prop_data?.pickup_address?.name != "" &&
                prop_data?.pickup_address?.name != null ? (
                  <div className="os-rate">
                    {prop_data.pickup_address?.name}
                  </div>
                ) : null}
                {prop_data?.pickup_address?.address != "" &&
                prop_data?.pickup_address?.city != "" &&
                prop_data?.pickup_address?.address != null &&
                prop_data?.pickup_address?.city != null ? (
                  <div className="os-pname ps-0">
                    {generate_address(prop_data?.pickup_address)}
                  </div>
                ) : (
                  <div className="os-pname ps-0">
                    {prop_data?.pickup_address?.map_address}
                  </div>
                )}
              </div>
              {/* call / chat */}
              <div className="col-3 p-0 end">
                {/* <div className="os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "a78b858bd49bf56c9fa495b641269677.svg"
                    }
                    className="w-20px"
                  />
                </div> */}
                {/* <div className="ms-3 os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                    }
                    className="w-20px"
                  />
                </div> */}
              </div>
            </div>
            {/* drop */}
            <div className="row pb-2">
              <div className="col-1 p-0">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "2f0620ddc47a95162db3b88a35358317.png"
                  }
                  className="w-20px"
                />
              </div>
              <div className="col-8 p-0">
                <div className="d-flex align-items-center pb-1">
                  <div className="os-dark pe-1">Delivery</div>
                  {prop_data?.delivery_address?.type_value != "" &&
                  prop_data?.delivery_address?.type_value != null ? (
                    <>
                      <div className="os-light pe-1">to</div>
                      <div className="os-dark uppercase">
                        {prop_data?.delivery_address?.type_value}
                      </div>
                    </>
                  ) : null}
                  {prop_data?.delivery_address?.other_type != "" &&
                  prop_data?.delivery_address?.other_type != null ? (
                    <>
                      <div className="os-light pe-1">to</div>
                      <div className="os-dark uppercase">
                        {prop_data?.delivery_address?.other_type}
                      </div>
                    </>
                  ) : null}
                </div>
                {prop_data?.delivery_address?.name != "" &&
                prop_data?.delivery_address?.name != null ? (
                  <div className="os-rate">
                    {prop_data.delivery_address?.name}
                  </div>
                ) : null}
                {prop_data?.delivery_address?.address != "" &&
                prop_data?.delivery_address?.city != "" &&
                prop_data?.delivery_address?.address != null &&
                prop_data?.delivery_address?.city != null ? (
                  <div className="os-pname ps-0">
                    {generate_address(prop_data?.delivery_address)}
                  </div>
                ) : (
                  <div className="os-pname ps-0">
                    {prop_data?.delivery_address?.map_address}
                  </div>
                )}
              </div>
              {/* call / chat */}
              <div className="col-3 p-0 end">
                {/* <div className="os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "a78b858bd49bf56c9fa495b641269677.svg"
                    }
                    className="w-20px"
                  />
                </div> */}
                {/* <div className="ms-3 os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                    }
                    className="w-20px"
                  />
                </div> */}
              </div>
            </div>
          </>
        ) : (
          prop_data?.products?.map((product: any, i: any) => (
            <div className="mb-2">
              <div className="d-flex" key={i}>
                {/* {get_deit_icon(product)} */}
                <div className="os-qty">{product?.quantity} ×</div>
                <div className="os-pname">{product?.variant_name}</div>
              </div>
              {product.addons?.length > 0 && (
                <div className="ps-4 fs-12 text-muted">
                  {product.addons?.map((addon: any, j: any) => (
                    <>
                      <span className="white-space-nowrap">
                        {addon?.quantity} <span>x </span> {addon?.name}
                      </span>
                      {product.addons[j + 1]?.name && <>, </>}
                    </>
                  ))}
                </div>
              )}
            </div>
          ))
        )}

        <hr className="os-hr m-0" />

        <div className="row py-2">
          <div className="col-6 os-tot">Total Bill</div>
          <div className="col-6 end os-tot">₹ {prop_data.amount}</div>
        </div>
      </div>
    );
  };

  const OrderListCardComponent = (ol_props: any) => {
    async function call_dp(order_id: any) {
      console.log("call dp :", order_id);
      setOrdersData((prevalue: any) => {
        prevalue[order_id].dp_call_loading = true;
        return { ...prevalue };
      });
      let pass_data = {
        get: {
          order_id: order_id,
          callto: "delivery_partner",
        },
      };
      let data_res = await api("/call", pass_data);
      if ((data_res.status_code = 200)) {
        setOrdersData((prevalue: any) => {
          prevalue[order_id].dp_call_loading = false;
          return { ...prevalue };
        });
        if (data_res?.response?.phone_number) {
          if (context?.application?.platform == "android") {
            window.open("tel:" + data_res?.response?.phone_number);
          } else {
            window.location.href = "tel:" + data_res?.response?.phone_number;
          }
        }
      }
    }

    async function chat(get_data: any) {
      setOrdersData((prevalue: any) => {
        prevalue[get_data.order_id].dp_chat_loading = true;
        return { ...prevalue };
      });
      let pass_data = {
        get: {
          order_id: get_data.order_id,
          chatto: get_data.chatto,
        },
      };
      let data: any = await api("/chat", pass_data);
      if (data.status_code == 200) {
        setOrdersData((prevalue: any) => {
          prevalue[get_data.order_id].dp_chat_loading = false;
          return { ...prevalue };
        });
        if (data?.response?.sb_user_id && data?.response?.channel_url) {
          SetChatModal({
            is_open: true,
            data: {
              orderId: get_data.order_id,
              sb_user_id: data?.response?.sb_user_id,
              channel_url: data?.response?.channel_url,
              title: data?.response?.user_name,
              profile_url: data?.response?.profile_url,
            },
          });
        }
      }
    }

    function get_nav_link(get_data: any) {
      let nav_link: any = "";
      // if (
      //   get_data.order_status_id != 6 &&
      //   get_data.order_status_id != 4 &&
      //   get_data.order_status_id != 3
      // ) {
      //   nav_link = "/live_track?o_id=" + get_data.id;
      // } else {
      //   nav_link = "/order_details?o_id=" + get_data.id;
      // }
      nav_link = "/order_details?o_id=" + get_data.id;
      return nav_link;
    }

    return (
      <div className="pb-2 px-1" key={ol_props.index}>
        <div className="card radius-10 shadow mb-2 h-100">
          <Link to={get_nav_link(ol_props.data)}>
            <OrderHeader data={ol_props.data} />
          </Link>

          {ol_props.data?.sector_id == 4 ? (
            <OrderPickupDropCompany data={ol_props.data} />
          ) : (
            <OrderSeller data={ol_props.data} />
          )}
          <Link to={get_nav_link(ol_props.data)}>
            <OrderProductDetails data={ol_props.data} />
          </Link>

          {ol_props.data.order_status_id != 1 &&
          ol_props.data.order_status_id != 3 &&
          ol_props.data.order_status_id != 4 &&
          ol_props.data.order_status_id < 5 &&
          Object.keys(ol_props.data.pickup_drop.hyperlocal_delivery).length ==
            0 ? (
            <div className="shop-div row">
              <div className="col-12 d-flex p-0">
                <div className="line-h-17px">
                  <span className="os-sta">
                    We are looking for a delivery partner to pick your order.
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {/* delivery person */}
          {ol_props.data?.sector_id == 4 &&
          ol_props.data?.order_status_id != 6 ? (
            ol_props.data.hasOwnProperty("pickup_drop") ? (
              <>
                <>
                  {Object.keys(ol_props.data?.pickup_drop?.hyperlocal_delivery)
                    .length > 0 ? (
                    <div className="shop-div row">
                      <div className="col-2 p-0 center">
                        {ol_props.data.hasOwnProperty("pickup_drop") ? (
                          ol_props.data?.pickup_drop?.hyperlocal_delivery
                            ?.delivery_partner_dp != null &&
                          ol_props.data?.pickup_drop?.hyperlocal_delivery
                            ?.delivery_partner_dp != "" ? (
                            <img
                              src={
                                ol_props.data?.pickup_drop?.hyperlocal_delivery
                                  ?.delivery_partner_dp
                              }
                              className="os-del-img"
                            />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Image_url +
                                "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
                              }
                              className="os-del-img"
                            />
                          )
                        ) : null}
                      </div>
                      <div className="col-7 d-flex p-0">
                        <div className="line-h-17px">
                          <span className="os-name">
                            {
                              ol_props.data?.pickup_drop?.hyperlocal_delivery
                                ?.delivery_partner_name
                            }
                          </span>
                          <span className="os-sta">
                            {
                              ol_props.data?.pickup_drop?.hyperlocal_delivery
                                ?.status
                            }
                          </span>
                        </div>
                      </div>
                      {/* call/ chat */}
                      <div className="col-3 p-0 end">
                        {ol_props.data?.pickup_drop?.hyperlocal_delivery
                          .delivery_partner_mobile ? (
                          <>
                            <div className="os-icon shadow center">
                              {ordersData[ol_props?.id]?.dp_call_loading ? (
                                <Spinner />
                              ) : (
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "a78b858bd49bf56c9fa495b641269677.svg"
                                  }
                                  onClick={() => call_dp(ol_props?.data.id)}
                                  className="w-20px"
                                />
                              )}
                            </div>
                            <div className="ms-3 os-icon shadow center">
                              {ordersData[ol_props?.id]?.dp_chat_loading ? (
                                <Spinner />
                              ) : (
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                  }
                                  onClick={() => {
                                    chat({
                                      order_id: ol_props?.data.id,
                                      chatto: "delivery_partner",
                                    });
                                  }}
                                  className="w-20px"
                                />
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </>
              </>
            ) : null
          ) : ol_props.data.hasOwnProperty("delivery") &&
            ol_props.data?.delivery.length > 0 ? (
            <>
              <>
                {Object.keys(ol_props.data?.delivery[0]?.hyperlocal_delivery)
                  .length > 0 ? (
                  <div className="shop-div row">
                    <div className="col-2 p-0 center">
                      {ol_props.data.hasOwnProperty("delivery") &&
                      ol_props.data?.delivery.length > 0 ? (
                        ol_props.data?.delivery[0]?.hyperlocal_delivery
                          ?.delivery_partner_dp != null &&
                        ol_props.data?.delivery[0]?.hyperlocal_delivery
                          ?.delivery_partner_dp != "" ? (
                          <img
                            src={
                              ol_props.data?.delivery[0]?.hyperlocal_delivery
                                ?.delivery_partner_dp
                            }
                            className="os-del-img"
                          />
                        ) : (
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
                            }
                            className="os-del-img"
                          />
                        )
                      ) : null}
                    </div>
                    <div className=  {ol_props.data?.delivery[0]?.hyperlocal_delivery
                        .delivery_partner_mobile ? "col-7 d-flex p-0" : "col-10 d-flex p-0"}>
                      <div className="line-h-17px">
                        <span className="os-name">
                          {
                            ol_props.data?.delivery[0]?.hyperlocal_delivery
                              ?.delivery_partner_name
                          }
                        </span>
                        <span className="os-sta">
                          {
                            ol_props.data?.delivery[0]?.hyperlocal_delivery
                              ?.status
                          }
                        </span>
                      </div>
                    </div>
                    {/* call/ chat */}
                    <div className="col-3 p-0 end">
                      {ol_props.data?.delivery[0]?.hyperlocal_delivery
                        .delivery_partner_mobile ? (
                        <>
                          <div className="os-icon shadow center">
                            {ordersData[ol_props?.id]?.dp_call_loading ? (
                              <Spinner />
                            ) : (
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "a78b858bd49bf56c9fa495b641269677.svg"
                                }
                                onClick={() => call_dp(ol_props?.data.id)}
                                className="w-20px"
                              />
                            )}
                          </div>
                          <div className="ms-3 os-icon shadow center">
                            {ordersData[ol_props?.id]?.dp_chat_loading ? (
                              <Spinner />
                            ) : (
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                }
                                onClick={() => {
                                  chat({
                                    order_id: ol_props?.data.id,
                                    chatto: "delivery_partner",
                                  });
                                }}
                                className="w-20px"
                              />
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </>
            </>
          ) : null}

          {/* track order */}
          {ol_props.data?.sector_id == 4 &&
          ol_props.data?.order_status_id != 6 ? (
            ol_props.data.hasOwnProperty("pickup_drop") ? (
              Object.keys(ol_props.data?.pickup_drop?.hyperlocal_delivery)
                .length > 0 ? (
                <Link to={"/live_track?o_id=" + ol_props.data.id}>
                  <div className="p-2">
                    <div className="os-rr-div center shadow ">
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "6607109d3bf02c6450722e7022906d3d.svg"
                        }
                        className="w-20px"
                      />
                      <div className="os-rate ps-1">Track Order</div>
                    </div>
                  </div>
                </Link>
              ) : null
            ) : null
          ) : ol_props.data.hasOwnProperty("delivery") &&
            ol_props.data?.delivery.length > 0 ? (
            Object.keys(ol_props.data?.delivery[0]?.hyperlocal_delivery)
              .length > 0 ? (
              <Link to={"/live_track?o_id=" + ol_props.data.id}>
                <div className="p-2">
                  <div className="os-rr-div center shadow ">
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "6607109d3bf02c6450722e7022906d3d.svg"
                      }
                      className="w-20px"
                    />
                    <div className="os-rate ps-1">Track Order</div>
                  </div>
                </div>
              </Link>
            ) : null
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className=" pb-3 w-100 bg-body-color">
      <div className="px-2 py-3 bg-white mb-1 fixed-search-bar custom-container l-0 r-0">
        <h5 className="mb-0 px-1">My Orders</h5>
      </div>
      <div className="custom-container px-1 mt-5 pt-sm-3 pt-md-5">
        <div className="pt-2">
          {visible_content ? (
            orders.length > 0 ? (
              <InfiniteScroll
                dataLength={orders.length}
                next={() => {
                  let send_data = {
                    page_no: page_no,
                    load_more: true,
                  };
                  get_orders(send_data);
                }}
                className="row py-1 overflowy-auto"
                hasMore={next_page}
                loader={
                  <div className="text-center p-5 col-12">
                    <Spinner />
                  </div>
                }
              >
                {orders?.map((order: any, index: any) => (
                  <OrderListCardComponent data={order} index={index} />
                ))}
              </InfiniteScroll>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="card p-3 text-center">
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "a456c01d41fd399a50f9b7c6d4b9d084.png"
                      }
                      className="empty-img"
                    />
                    <p>You have placed no order yet!</p>
                    <Link to={"/"} className="btn btn-primary">
                      Shop Now
                    </Link>
                  </div>
                </div>
              </div>
            )
          ) : (
            [1, 2, 3, 4].map((value: any, i: any) => (
              <div className="col-lg-12 p-1" key={i}>
                <OrderSkeleton />
              </div>
            ))
          )}
        </div>
      </div>

      <OrderChat
        open={chatModal.is_open}
        data={chatModal.data}
        close={(data: any) => {
          SetChatModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // new_order();
          }
        }}
      />
    </div>
  );
}
