import React, { useState, useEffect, useContext, Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import loadable from "@loadable/component";
import DataContext from "../../utils/Context/DataContext";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "../../utils/Loader";
import { api } from "../../utils/Api";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import WishList from "../../pages/Common/WishList/WishList";
import VendorsCategoryPage from "../../pages/VendorWiseLayout/VendorsCategory/VendorsCategoryPage";
import { mobileApp } from "../../utils/MobileApp";
import CartNew from "../../pages/Common/Cart/CartNew";
import OrderPageNew from "../../pages/Common/OrderPage/OrderPageNew";
import MyOrders from "../../pages/Common/OrderPage/MyOrders";
import OrderDetailsUi from "../../pages/Common/OrderDetails/OrderDetailsUi";
import AddAddressNew from "../../pages/Common/Address/AddAddressNew";
import AddressNew from "../../pages/Common/Address/AddressNew";
import MapLocationPickModal from "../../pages/Common/Address/MapLocationPickModal";
import NewSearch from "../../pages/VendorWiseLayout/Search/NewSearch";
import SearchResult from "../../pages/VendorWiseLayout/Search/SearchResultUI";
// import LocationAddressList from "../LocationAddressList/LocationAddressList";
import LiveTrackingModalUI from "../../pages/Common/LiveTracking/LiveTrackingModalUI";
import PickupDropDashboardUI from "../../pages/PickupDropLayout/Dashboard/DashboardUI";
import WishListUI from "../../pages/Common/WishList/WishlistUI";
import LocationAddressList from "../LocationAddressList/LocationAddressList";

// // layout
// import CombineLayout from '../CombineLayout/CombineLayout'

// // Common pages
// import Home from '../../Pages/Common/Home/Home'

// // VendorWiseLayout pages
// import Dashboard from '../../Pages/VendorWiseLayout/Dashboard/Dashboard'
// import Shop from '../../Pages/VendorWiseLayout/Shop/Shop'

// // ProductWiseLayout pages
// import ProductDashboard from '../../Pages/ProductWiseLayout/Dashboard/Dashboard'

// //////////////////////////////////////////////////////////////////////////////////////

// layout
const CombineLayout = loadable(() => import("../CombineLayout/CombineLayout"));
const MenuLayout = loadable(() => import("../MenuLayout/MenuLayout"));

// Common pages
const Home = loadable(() => import("../../pages/Common/Home/Home"));
const Cart = loadable(() => import("../../pages/Common/Cart/Cart"));
const OrderAddress = loadable(
  () => import("../../pages/Common/OrderAddress/OrderAddress")
);
const OrderSummary = loadable(
  () => import("../../pages/Common/OrderSummary/OrderSummary")
);
const OrderSummarySingleAddress = loadable(
  () =>
    import("../../pages/Common/OrderSummarySingle/OrderSummarySingleAddress")
);
const SelectAddress = loadable(
  () => import("../../pages/Common/SelectAddress/SelectAddress")
);
const CompleteOrder = loadable(
  () => import("../../pages/Common/CompleteOrder/CompleteOrder")
);
const OrderPage = loadable(
  () => import("../../pages/Common/OrderPage/OrderPageOld")
);
const OrderDetails = loadable(
  () => import("../../pages/Common/OrderDetails/OrderDetails")
);

// VendorWiseLayout pages
const Dashboard = loadable(
  () => import("../../pages/VendorWiseLayout/Dashboard/Dashboard")
);
const SkeletonDashboard = loadable(
  () => import("../../pages/VendorWiseLayout/Dashboard/SkeletonDashboard")
);
const Shop = loadable(
  () => import("../../pages/VendorWiseLayout/Shop/Shop-old")
);

const Shop1 = loadable(() => import("../../pages/VendorWiseLayout/Shop/Shop2"));

const Grocery = loadable(
  // () => import("../../pages/VendorWiseLayout/GroceryUI/Grocery")
  () => import("../../pages/VendorWiseLayout/Grocery/Grocery1")
);

const ShopCategoryPage = loadable(
  // () => import("../../pages/VendorWiseLayout/ShopCategoryPageUI/ShopCategoryPage")
  () => import("../../pages/VendorWiseLayout/ShopCategoryPage/ShopCategoryPage")
);

const SkeletonShop = loadable(
  () => import("../../pages/VendorWiseLayout/Shop/SkeletonShop")
);

// const VendorWiseSearch = loadable(
//   () => import("../../pages/VendorWiseLayout/Search/Search-old")
// );
const VendorWiseSearch = loadable(
  () => import("../../pages/VendorWiseLayout/Search/Search")
);

const VendorWiseSearchResult = loadable(
  () => import("../../pages/VendorWiseLayout/Search/SearchResult")
)

// ProductWiseLayout pages
const ProductDashboard = loadable(
  () => import("../../pages/ProductWiseLayout/Dashboard/Dashboard")
);

const ProductSkeletonDashboard = loadable(
  () =>
    import("../../pages/ProductWiseLayout/Dashboard/ProductSkeletonDashboard")
);

const ProductShop = loadable(
  () => import("../../pages/ProductWiseLayout/Shop/ProductShop")
);

const ProductSkeletonShop = loadable(
  () => import("../../pages/ProductWiseLayout/Shop/ProductSkeletonShop")
);

// PickupDropLayout pages
const PickupDropDashboard = loadable(
  () => import("../../pages/PickupDropLayout/Dashboard/Dashboard")
);

// ComingSoon pages
const ComingSoon = loadable(
  () => import("../../pages/Common/ComingSoon/ComingSoon")
);

// QRPage pages
const QRPage = loadable(() => import("../../pages/Common/QRPage/QRPage"));

const LiveTracking = loadable(
  () => import("../../pages/Common/LiveTracking/LiveTracking")
);

// Category pages
const CategoryPage = loadable(
  () => import("../../pages/Common/Category/Category")
);

// Notifications pages
const NotificationsPage = loadable(
  () => import("../../pages/Common/Notifications/Notifications")
);

// ViewNotifications pages
const ViewNotificationsPage = loadable(
  () => import("../../pages/Common/Notifications/ViewNotification")
);

// CodCompleteOrder pages
const CodCompleteOrderPage = loadable(
  () => import("../../pages/Common/CodCompleteOrder/CodCompleteOrder")
);

// Terms pages
const TermsPage = loadable(() => import("../../pages/Common/Terms/Terms"));

let badge_call_interval: any;

export default function MainLayout(props: any) {
  const context: any = useContext(DataContext);
  const [is_menu_open, SetIsMenuOpen] = useState(false);

  const [outlet_context_data, SetOutletContextData] = useState({
    have_back: false,
    title: "",
    link: {},
  });

  const [init_data, SetInitData] = useState({});
  const [id_value, SetIdValue] = useState(useParams());
  const [application, SetApplication] = useState({ platform: "web" });
  const slug_value = useParams();

  useEffect(() => {
    console.log("MainLayout onmount context :", context);
    console.log("MainLayout onmount slug_value :", slug_value);

    get_init();
    idle();
    return () => {
      clearInterval(badge_call_interval);
    };
  }, []);

  async function get_init() {
    let url = window.location.pathname;
    let domain = url.split("/");

    let section_slug: any, seller_slug: any, category_slug: any;

    if (
      domain[1] != "order" &&
      domain[1] != "live_track" &&
      domain[1] != "order_details" &&
      domain[1] != "cart" &&
      domain[1] != "wishlist"
    ) {
      section_slug = domain[1];
    }

    if (domain[2] == "category") {
      category_slug = domain[3];
    } else {
      seller_slug = domain[2];
    }

    console.log("url :", url);
    console.log("domain :", domain);
    console.log("section_slug :", section_slug);
    console.log("seller_slug :", seller_slug);
    console.log("slug_value : ", slug_value);

    let pass_data: any = {
      get: {
        // section_slug: section_slug,
        // seller_slug: seller_slug,
        // category_slug: category_slug,
      },
    };
    if (section_slug != undefined && section_slug != "") {
      pass_data.get.section_slug = section_slug;
    }

    if (seller_slug != undefined && seller_slug != "") {
      pass_data.get.seller_slug = seller_slug;
    }

    if (category_slug != undefined && category_slug != "") {
      pass_data.get.category_slug = category_slug;
    }

    let response = await api("/init", pass_data);
    console.log("app_init response :", response);
    if (response.status_code == 200) {
      SetInitData(response.response);
      context.SetAppData((prevValue) => {
        prevValue.init_called = true;
        if (response.response.hasOwnProperty("store_details")) {
          prevValue.store_details = response.response.store_details;
        }

        if (response.response.hasOwnProperty("force_redirect")) {
          if (response.response.force_redirect != "") {
            window.location.href = response.response.force_redirect;
          }
        }

        if (response.response.hasOwnProperty("image_base_url")) {
          prevValue.image_base_url = response.response.image_base_url;
        }

        if (response.response.hasOwnProperty("image_sizes")) {
          prevValue.image_sizes = response.response.image_sizes;
        }

        if (response.response.hasOwnProperty("user_details")) {
          prevValue.user_details = response.response.user_details;
        }

        if (response.response.hasOwnProperty("delivery_address")) {
          // hided on 040820241240
          let delivery_address_value = response.response.delivery_address;
          delivery_address_value?.map((ele) => {
            ele.checked = false;
            ele.disabled = false;
          });
          prevValue.delivery_address = delivery_address_value;
          // hide end
        }

        if (response.response.hasOwnProperty("current_section")) {
          prevValue.selected_section = response.response.current_section;
          prevValue.header_content = response.response.current_section.name;
        } else {
          prevValue.selected_section = {};
          prevValue.header_content = "Shop";
        }

        if (response.response.hasOwnProperty("current_vendor")) {
          // prevValue.selected_vendor = response.response.current_vendor[0]
          // prevValue.selected_vendor_outlet =
          //   response.response.current_vendor[0].outlets[0]

          prevValue.restaurant.brand_id =
            response.response.current_vendor[0].brand_id;
          prevValue.restaurant.brand_name =
            response.response.current_vendor[0].brand_name;
          prevValue.restaurant.favourite =
            response.response.current_vendor[0].favourite;
          prevValue.restaurant.outlets =
            response.response.current_vendor[0].outlets;
          prevValue.selected_vendor = response.response.current_vendor[0];
          // prevValue.selected_vendor_outlet =
          //   response.response.current_vendor[0].outlets[0];
          //
          let checked_outlet = response.response.current_vendor[0].outlets.find(
            (ele) => ele.checked == true
          );
          console.log("checked_outlet in main layout :", checked_outlet);
          prevValue.selected_vendor_outlet = checked_outlet;
        }

        if (response.response.hasOwnProperty("shipment_details")) {
          prevValue.shipment_details = response.response.shipment_details;
          let sec_data = response.response.shipment_details;
          // if(response.response.hasOwnProperty("current_section")){
          //   let cur_sec_data = response.response.current_section;
          //   sec_data.sections.map((ele:any)=>{
          //     if(ele.section_id == cur_sec_data.id){
          //       if(ele.delivery_address != null){
          //         prevValue.lat_lang = { lat: cur_sec_data.delivery_address_details.latitude, lang: cur_sec_data.delivery_address_details.longitude };
          //       }else{
          //         prevValue.lat_lang = { lat: cur_sec_data.lat, lang: cur_sec_data.lng };
          //       }
          //     }
          //   })
          // }
        }

        if (response.response.hasOwnProperty("all_sections")) {
          let sections_data = response.response.all_sections;
          // sections_data.unshift({
          //   id: "0",
          //   slug: "all",
          //   name: "All",
          // });
          prevValue.all_sections = sections_data;
        }

        if (response.response.hasOwnProperty("tutorials")) {
          prevValue.tutorials = response.response.tutorials;
          // SetOutletContextData((prevalue)=>{
          //   prevalue.auth = response.response.auth;
          //   return {...prevalue}
          // })
        }

        if (response.response.hasOwnProperty("current_categories")) {
          prevValue.selected_category = response.response.current_categories;
        }

        if (response.response.hasOwnProperty("auth")) {
          prevValue.auth = response.response.auth;
          if (response.response.auth.hasOwnProperty("mobile_app")) {
            prevValue.have_mobile_app_key = true;
            prevValue.mobile_app_key_value = response.response.auth.mobile_app;
          } else {
            prevValue.have_mobile_app_key = false;
            prevValue.mobile_app_key_value = "";
          }
        }

        if (response.response.hasOwnProperty("application")) {
          SetApplication(response.response.application);
          prevValue.application = response.response.application;
          if (
            response.response.application.platform === "ios" ||
            response.response.application.platform === "android"
          ) {
            let mobileObj: any = {
              function: "dashboard",
              app_type: "react_web",
              accuracy: "Low",
            };
            if (response.response.application.hasOwnProperty("token")) {
              mobileObj.userid = response.response.user_details.id;
              mobileObj.username = response.response.user_details.username;
              mobileObj.token = response.response.application.token;
            }
            if (
              response.response.application.hasOwnProperty("notification_token")
            ) {
              mobileObj.notification_token =
                response.response.application.notification_token;
            } else {
              mobileObj.notification_token = null;
            }
            mobileApp(mobileObj);
          }
        }

        if (response.response.hasOwnProperty("countries")) {
          let c_value = response.response.countries;
          c_value?.map((ele: any) => {
            ele.label = ele.nicename;
            ele.value = ele.id;
          });
          prevValue.country_options = c_value;
        }

        if (response.response.hasOwnProperty("specifications")) {
          let spec_value = response.response.specifications;
          spec_value?.map((ele: any) => {
            ele?.options?.map((opt_ele) => {
              opt_ele.checked = false;
              opt_ele.disabled = false;
            });
          });
          prevValue.specifications = spec_value;
        }

        // if (response.response.hasOwnProperty("new_cart_count")) {
        //   prevValue.new_cart_count = response.response.new_cart_count;
        //   localStorage.setItem(
        //     "new_cart_count",
        //     response.response.new_cart_count
        //   );
        // }

        if (response.response.hasOwnProperty("section_cart_count")) {
          let cart_data = response.response.section_cart_count;
          prevValue.section_cart_count = cart_data;
          // if (slug_value != undefined && slug_value != "") {
          console.log("slug_value :", slug_value);
          console.log(
            "Object.keys(slug_value).length > 0 :",
            Object.keys(slug_value).length > 0
          );

          // if (Object.keys(slug_value).length > 0) {
          //   cart_data.sections.map((ele: any) => {
          //     if (ele.slug == slug_value.section_slug) {
          //       prevValue.new_cart_count = ele.new_cart_count;
          //     }
          //   });
          // } else {
          //   prevValue.new_cart_count = cart_data.all;
          // }

          if (response.response.hasOwnProperty("current_section")) {
            let section_data = response.response.current_section;
            cart_data.sections.map((ele: any) => {
              if (ele.slug == section_data.slug) {
                prevValue.new_cart_count = ele.new_cart_count;
              }
            });
          } else {
            prevValue.new_cart_count = cart_data.all;
          }

          console.log("prevValue.new_cart_count :", prevValue.new_cart_count);
          // localStorage.setItem(
          //   "new_cart_count",
          //   response.response.new_cart_count
          // );
        }

        if (response.response.hasOwnProperty("new_notifications")) {
          prevValue.new_notifications = response.response.new_notifications;
          localStorage.setItem(
            "new_notifications",
            response.response.new_notifications
          );
        }

        // badge_call_interval = setInterval(function () {
        //   get_badge(prevValue);
        // }, 40000);

        console.log("prevValue init context  :", prevValue);
        return { ...prevValue };
      });

      get_badge({});
      // badge_call_interval = setInterval(function () {
      //   get_badge({});
      // }, 40000);
    }
  }

  async function idle() {
    let data: any = await api("/idle");
    if (data.status_code == 200) {
    }
  }

  async function get_badge(get_data: any) {
    let pass_data = {
      get: {},
    };
    // if (Object.keys(get_data.selected_section).length > 0) {
    //   pass_data.get.section_slug = get_data.selected_section.slug;
    // }
    console.log("get_badge pass_data :", pass_data);
    let response = await api(
      "/badges",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    console.log("get_badge response :", response);
    if (response.status_code == 200) {
      // context.SetAppData((prevalue: any) => {
      //   prevalue.new_notifications = response.response.new_notifications;
      //   prevalue.new_cart_count = response.response.new_cart_count;
      //   return { ...prevalue };
      // });
      if (response.response.hasOwnProperty("new_notifications")) {
        localStorage.setItem(
          "new_notifications",
          response.response.new_notifications
        );
      }
      if (response.response.hasOwnProperty("new_cart_count")) {
        localStorage.setItem(
          "new_cart_count",
          response.response.new_cart_count
        );
      }
      setTimeout(() => {
        get_badge({});
      }, 40000);
    }
  }

  function on_menu_click(data) {
    console.log("MainLayout on menu/back click :", data);
    SetIsMenuOpen(data);
  }

  return (
    <BrowserRouter>
      {/* <Suspense fallback={<DashboardSkeleton />}> */}
      <Routes>
        <Route
          path="/"
          element={<CombineLayout promise_tracker={props.promise_tracker} />}
        >
          {/* {usePromiseTracker} */}
          {/* <Route index element={<OrderDetailsNew />} /> */}

          <Route index element={<Home />} />

          <Route path="NewSearch" element={<NewSearch />} />
          <Route path="SearchResult" element={<SearchResult />} />
          {/* <Route path="AddressNew" element={<AddressNew />} /> */}
          {/* <Route path="AddAddressNew" element={<AddAddressNew />} /> */}
          {/* <Route path="AddressNew" element={<AddressNew />} /> */}
          <Route path="CartNew" element={<CartNew />} />

          {/* <Route path="address_list" element={< LocationAddressList/>} /> */}
          <Route
            path="live_tracking_modal_ui"
            element={<LiveTrackingModalUI />}
          />
          <Route
            path="pickup_drop_dashboard_ui"
            element={<PickupDropDashboardUI />}
          />
          <Route path="wishlist_ui" element={<WishListUI />} />
          <Route path="/address_list" element={<LocationAddressList />} />

          {/* <Route
            path=":section_slug"
            element={
              context.app_data.selected_section.order_type == "Products" ? (
                <ProductDashboard have_back={false} need_login={false} />
              ) : context.app_data.selected_section.order_type == "Vendors" ? (
                <Dashboard
                  have_back={false}
                  need_login={false}
                  fallback={<SkeletonDashboard />}
                />
              ) : null
            }
          /> */}

          <Route
            path=":section_slug"
            element={
              context.app_data.selected_section.sector == "4" ? (
                <PickupDropDashboard have_back={false} need_login={true} />
              ) : context.app_data.selected_section.order_type == "Products" ? (
                <ProductDashboard have_back={false} need_login={false} />
              ) : context.app_data.selected_section.order_type == "Vendors" ? (
                // context.app_data.selected_section.sector == "3" ? (
                //   <ProductDashboard
                //     have_back={false}
                //     need_login={false}
                //     fallback={<ProductSkeletonDashboard />}
                //   />
                // ) : (
                //   <Dashboard
                //     have_back={false}
                //     need_login={false}
                //     fallback={<SkeletonDashboard />}
                //   />
                // )
                <Dashboard
                  have_back={false}
                  need_login={false}
                  fallback={<SkeletonDashboard />}
                />
              ) : null
            }
          />

          <Route
            path="comingsoon"
            element={<ComingSoon have_back={true} need_login={false} />}
          />

          <Route
            path=":section_slug/:seller_slug"
            // element={
            //   context.app_data.selected_section.order_type == "Products" &&
            //   context.app_data.selected_section.sector == "2" ? (
            //     // <Shop have_back={true} need_login={false} />
            //     <Shop1 have_back={true} need_login={false} />
            //   ) : context.app_data.selected_section.order_type == "Products" &&
            //     context.app_data.selected_section.sector == "1" ? (
            //     <Grocery have_back={true} need_login={false} />
            //   ) : context.app_data.selected_section.order_type == "Vendors" ? (
            //     context.app_data.selected_section.sector == "3" ? (
            //       <ProductShop
            //         have_back={false}
            //         need_login={false}
            //         fallback={<ProductSkeletonShop />}
            //       />
            //     ) : (
            //       // <Shop
            //       //   have_back={true}
            //       //   need_login={false}
            //       //   fallback={<SkeletonShop />}
            //       // />
            //       <Shop1
            //         have_back={true}
            //         need_login={false}
            //         fallback={<SkeletonShop />}
            //       />
            //     )
            //   ) : null
            // }
            element={
              context.app_data.selected_section.sector == "1" ? (
                <Grocery have_back={true} need_login={false} />
              ) : context.app_data.selected_section.sector == "2" ? (
                <Shop1
                  have_back={true}
                  need_login={false}
                  fallback={<SkeletonShop />}
                />
              ) : context.app_data.selected_section.sector == "3" ? (
                context.app_data.selected_section.order_type == "Vendors" ? (
                  <Grocery have_back={true} need_login={false} />
                ) : (
                  <ProductShop
                    have_back={false}
                    need_login={false}
                    fallback={<ProductSkeletonShop />}
                  />
                )
              ) : null
            }
          />

          <Route
            path=":section_slug/:seller_slug/category"
            element={<ShopCategoryPage have_back={true} need_login={false} />}
          />

          <Route
            path=":section_slug/search"
            element={
              context.app_data.selected_section.order_type ==
              "Products" ? null : context.app_data.selected_section // <Shop have_back={true} need_login={false} />
                  .order_type == "Vendors" ? (
                // <Shop
                //   have_back={true}
                //   need_login={false}
                //   fallback={<SkeletonShop />}
                // />
                <VendorWiseSearch have_back={true} need_login={false} />
              ) : null
            }
          />

          <Route
            path=":section_slug/search_result"
            element={
              context.app_data.selected_section.order_type ==
              "Products" ? null : context.app_data.selected_section // <Shop have_back={true} need_login={false} />
                  .order_type == "Vendors" ? (
                // <Shop
                //   have_back={true}
                //   need_login={false}
                //   fallback={<SkeletonShop />}
                // />
                <VendorWiseSearchResult have_back={true} need_login={false} />
              ) : null
            }
          />

          <Route
            path=":section_slug/category"
            // path=":section_slug/category/:category_slug"
            element={
              // context.app_data.selected_section.order_type == 'Products' ? (
              //   <Shop have_back={true} />
              // ) : context.app_data.selected_section.order_type == 'Vendors' ? (
              //   <Shop have_back={true} />
              // ) : null
              // <p>Category</p>
              <VendorsCategoryPage have_back={true} need_login={false} />
            }
          />

          <Route path="menu" element={<MenuLayout />}>
            {/* <Route index element={<Navigate to="cart"/>} /> */}
            {/* <Route path="category/:section_slug" element={<Cart /> } />
            <Route path="cart" element={<Cart have_back={false} /> } />
            <Route path="order" element={<OrderPage have_back={false} />} /> */}
          </Route>

          <Route
            path="cart"
            element={<Cart have_back={true} need_login={false} />}
          />

          {/* <Route
            path="order"
            element={<OrderPage have_back={true} need_login={true} />}
          /> */}
          <Route
            path="order"
            element={<MyOrders have_back={true} need_login={true} />}
          />
          <Route
            path="myordersui"
            element={<OrderPageNew have_back={true} need_login={true} />}
          />

          <Route
            path="order_details"
            element={<OrderDetails have_back={true} need_login={true} />}
          />

          <Route
            path="order_details_ui"
            element={<OrderDetailsUi have_back={true} need_login={true} />}
          />

          {/* <Route path="wishlist" element={<p>Wishlist</p>} /> */}
          <Route
            path="order_address"
            element={<OrderAddress have_back={true} need_login={true} />}
          />

          <Route
            path="order_summary/single"
            element={
              <OrderSummarySingleAddress have_back={true} need_login={true} />
            }
          />

          <Route
            path="order_summary/multiple"
            element={<OrderSummary have_back={true} need_login={true} />}
          />

          <Route
            path="order_summary/select_address"
            element={
              <SelectAddress
                from="main_layout"
                have_back={true}
                need_login={true}
              />
            }
          />

          <Route
            path="complete_order"
            element={<CompleteOrder have_back={false} need_login={true} />}
          />

          <Route
            path="wishlist"
            element={<WishList have_back={true} need_login={true} />}
          />

          <Route
            path="qr/:vendor_token"
            element={<QRPage have_back={true} need_login={false} />}
          />

          <Route
            path="live_track/"
            element={<LiveTracking have_back={true} need_login={false} />}
          />

          <Route
            path="category"
            element={<CategoryPage have_back={true} need_login={false} />}
          />

          <Route
            path="notification"
            element={<NotificationsPage have_back={true} need_login={false} />}
          />

          <Route
            path="notification/:n_id"
            element={
              <ViewNotificationsPage have_back={true} need_login={false} />
            }
          />

          <Route
            path="terms"
            element={<TermsPage have_back={true} need_login={false} />}
          />

          <Route
            path="cod_placed/:place_count"
            element={
              <CodCompleteOrderPage have_back={true} need_login={false} />
            }
          />
        </Route>
      </Routes>
      {/* </Suspense> */}
    </BrowserRouter>
  );
}
